<template>
    <div>
        <Header :num="'4'"></Header>
        <Banner :src="bannerImg" :title="title"></Banner>
        <section-title title="about us" sub-title="关于我们"></section-title>
        <div class="about">
            <div class="compony">
                <div class="img">
                    <img src="../assets/project-about-02.jpg" alt="" />
                </div>
                <div class="info">
                    <h2>公司简介 COMPONY PROFILE</h2>
                    <p>
                        湖南新展信息技术有限公司成立于2018年，主要致力于面向企业提供定制化产品研发与服务。公司重在技术创新以及精细化软件产品孵化，期望将概念变为现实，让信息化能广泛覆盖到各行各业。公司技术核心团队是由一支专业的行业精英组成，本着服务至上的宗旨，高质高效的目标产出，力求为合作企业解决各种技术创新难题，通过深度合作实现双赢，为企业双方在互联网+时代开拓一片新的疆土，抢占市场先机，提升企业核心竞争力与价值。
                    </p>

                </div>
            </div>
            <div class="team">
                <div class="text">

                    <p style="padding: 20px 0">
                        在公务航空行业领域中，公司重点目标是打造智慧通航的云平台，专注于为通用航空领域将运行数据转化为极具价值的决断力。凭借先进的信息化软件，企业能够迅速、明智、自信地实施决策和采取行动，通过精细化管理和业务优化为企业带来全新契机。
                    </p>

<!--                    <p>-->
<!--                        公司技术核心团队是由一支专业的行业精英组成，本着服务至上的宗旨，高质高效的目标产出，力求为合作企业解决各种技术创新难题，通过深度合作实现双赢，为企业双方在互联网+时代开拓一片新的疆土，抢占市场先机，提升企业核心竞争力与价值。-->
<!--                    </p>-->
<!--                    <p>-->
<!--                        公司技术核心团队是由一支专业的行业精英组成，本着服务至上的宗旨，高质高效的目标产出，力求为合作企业解决各种技术创新难题，通过深度合作实现双赢，为企业双方在互联网+时代开拓一片新的疆土，抢占市场先机，提升企业核心竞争力与价值。-->
<!--                    </p>-->
                </div>
            </div>
        </div>
<!--        <div style="margin-left: 94%">-->
<!--            <Affix :offset-bottom="80" style="">-->
<!--                <ButtonGroup vertical size="large">-->
<!--                    <Button type="primary" icon="logo-facebook"></Button>-->
<!--                    <Button  type="primary" icon="logo-twitter"></Button>-->
<!--                    <Button  type="primary"  icon="logo-googleplus"></Button>-->
<!--                    <Button  type="primary" icon="logo-tumblr"></Button>-->
<!--                </ButtonGroup>-->
<!--            </Affix>-->
<!--        </div>-->
<!--      <section-title-->
<!--          title="enterprise qualification"-->
<!--          sub-title="企业资质"-->
<!--      ></section-title>-->

        <section-title
                title="contact us"
                sub-title="联系我们（湖南长沙）"
        ></section-title>
        <ul>
            <li><img style="vertical-align: middle;margin-right: 8px;" src="../assets/icon-time.png" alt=""><span  style="vertical-align: middle" >服务时间: 周一至周五 (9:00 - 18:00)</span></li>
            <li><img  style="vertical-align: middle;margin-right: 8px;"  src="../assets/icon-contact.png" alt=""><span  style="vertical-align: middle" >联系电话: 0731-82297876</span></li>
            <li><img  style="vertical-align: middle;margin-right: 8px;"  src="../assets/icon-email.png" alt=""><span style="vertical-align: middle">联系邮箱: liyubai@gneway.com</span></li>
            <li><img   style="vertical-align: middle;margin-right: 8px;"  src="../assets/icon-address.png" alt=""><span  style="vertical-align: middle">地址: 湖南省长沙市岳麓区西中心T2-1610室</span></li>
        </ul>

        <section-title
            sub-title="联系我们（香港）"
        ></section-title>
        <ul>
          <li><img style="vertical-align: middle;margin-right: 8px;" src="../assets/icon-time.png" alt=""><span  style="vertical-align: middle" >服务时间: 周一至周五 (9:00 - 18:00)</span></li>
          <li><img  style="vertical-align: middle;margin-right: 8px;"  src="../assets/icon-contact.png" alt=""><span  style="vertical-align: middle" >联系电话: +852 22163940</span></li>
          <li><img  style="vertical-align: middle;margin-right: 8px;"  src="../assets/icon-email.png" alt=""><span style="vertical-align: middle">联系邮箱: liyubai@gneway.com</span></li>
          <li><img   style="vertical-align: middle;margin-right: 8px;"  src="../assets/icon-address.png" alt=""><span  style="vertical-align: middle">地址: 香港天后英皇道25號景星中心16樓</span></li>
        </ul>
        <Footer></Footer>
        <BackTop :height="100" :bottom="200">
            <div class="top">返回顶部</div>
        </BackTop>


    </div>
</template>
<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Banner from '../components/Banner'
import SectionTitle from '../components/SectionTitle'

export default {
    name: 'About',
    components: {
        Header,
        Footer,
        Banner,
        SectionTitle
    },
    data() {
        return {
            bannerImg: require('../assets/abount-banner.jpg'),
            title: '关于我们',
        }
    }
}
</script>
<style lang="less" scoped>
.about {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px 0 60px;
    .compony {
        margin-top: 30px;
        display: flex;
        .img {
            flex: 1;
            img {
                max-width: 100%;
            }
        }
        .info {
            flex: 2;
            margin-left: 60px;
            h2 {
                margin-bottom: 20px;
            }
        }
    }
    .team {
        background: url(../assets/project-about-01.jpg) no-repeat right top;
        margin-top: 100px;
        overflow: hidden;
        .text {
            width: 60%;
            background: rgba(238, 238, 238, 0.6);
            padding: 30px;
            margin: 60px;
        }
    }
    p {
        line-height: 30px;
        margin-bottom: 10px;
    }
}
.top{
    padding: 10px;
    background: rgba(0, 153, 229, .7);
    color: #fff;
    text-align: center;
    border-radius: 2px;
}

ul {
    width: 860px;
    margin: 30px auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
        width: 48%;
        background: #eee;
        margin-bottom: 30px;
        padding: 30px;
        font-size: 16px;
        box-sizing: border-box;
    }
}
</style>
